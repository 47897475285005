import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";
import { Map, Marker, ZoomControl } from "pigeon-maps";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataDisplay } from "../../common/DataDisplay";
import { HomebaseButton } from "../../common/HomebaseButton";
import { formatUtils } from "../../utils/format";
import { TransactionSummary } from "../transactions/Transactions";

export const Properties = () => {
  const [properties, setProperties] = useState<any[]>([]);

  useEffect(() => {
    // call to get properties
    setProperties([{}]);
  }, []);

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
        className="dash-card"
      >
        <Typography variant="h5" className="semi-bold" sx={{ p: 2 }}>
          My Properties
        </Typography>
        <Divider />
        <Box
          sx={{
            p: 2,
            height: 300,
            borderRadius: "10px",
          }}
        >
          <Map defaultCenter={[33.46033, -111.93422]} defaultZoom={16}>
            <ZoomControl />
            <Marker width={50} anchor={[33.46033, -111.93422]} />
          </Map>
        </Box>
        <Divider />
        <div
          style={{
            flexGrow: 1,
            maxHeight: "416px",
            overflow: "auto",
          }}
        >
          <table width="100%">
            {properties.length ? (
              properties.map((property, i) => <Property id={i} />)
            ) : (
              <tr style={{ height: "99px" }}>
                <td style={{ padding: "16px", textAlign: "center" }}>
                  <Typography variant="body1">
                    You have no properties
                  </Typography>
                </td>
              </tr>
            )}
          </table>
        </div>
        <Divider />
        <div>
          <Typography
            variant="body1"
            className="blue-text semi-bold"
            sx={{ p: 2, float: "right" }}
          >
            {formatUtils.formatMoney(10000)}
          </Typography>
          <Typography
            variant="body1"
            className="grey-text"
            sx={{ p: 2, float: "right" }}
          >
            Total Value Invested:
          </Typography>
        </div>
      </Paper>
    </Grid>
  );
};

export const PropertySummary = () => {
  const [properties, setProperties] = useState<any[]>([]);

  useEffect(() => {
    // call to get properties
    setProperties([{}]);
  }, []);

  return (
    <Paper
      sx={{
        maxHeight: 600,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      className="dash-card"
    >
      <Typography variant="h5" className="semi-bold" sx={{ p: 2 }}>
        My Properties
      </Typography>
      <Divider />
      <div style={{ flexGrow: 1 }}>
        <table width="100%">
          {properties.length > 0 ? (
            properties.map((property, i) => <Property id={i} />)
          ) : (
            <tr style={{ height: "99px" }}>
              <td style={{ textAlign: "center", padding: "16px" }}>
                <Typography variant="body1">You have no properties</Typography>
              </td>
            </tr>
          )}
        </table>
      </div>
      <Divider />
      <div>
        <Typography variant="caption" sx={{ p: 2, float: "right" }}>
          <a href="/dashboard/properties" className="clickable grey-text">
            {" "}
            View All {">"}
          </a>
        </Typography>
      </div>
    </Paper>
  );
};

export const PropertyDetails = () => {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Paper className="dash-card">
          <div style={{ padding: "16px" }}>
            <Typography variant="h5" className="semi-bold">
              The Cardinal
            </Typography>
            <Typography variant="body2" className="grey-text">
              McAllen, TX
            </Typography>
          </div>
          <Divider />
          <div style={{ padding: "16px" }}>
            <img
              src="https://cabinet-storage-production.imgix.net/7f2e8378-bd31-53fc-844a-89dd3aaa6d7e.jpg?w=2000&fit=crop&s=d8016c80415aa3d8d1f1760770404699"
              alt="Property"
              style={{
                width: "100%",
                height: 200,
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />
          </div>
          <div style={{ padding: "0px 16px" }}>
            <table width="100%">
              <tr>
                <td>
                  <Typography variant="body1" className="grey-text semi-bold">
                    Property Value
                  </Typography>
                </td>
                <td>
                  <Typography
                    variant="body1"
                    className="medium purple-text"
                    sx={{ float: "right" }}
                  >
                    {formatUtils.formatMoney(400000)}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant="body1" className="grey-text semi-bold">
                    Total Cash Earned
                  </Typography>
                </td>
                <td>
                  <Typography
                    variant="body1"
                    className="medium purple-text"
                    sx={{ float: "right" }}
                  >
                    {formatUtils.formatMoney(-988.25)}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant="body1" className="grey-text semi-bold">
                    Redeemable Rent
                  </Typography>
                </td>
                <td>
                  <Typography
                    variant="body1"
                    className="medium purple-text"
                    sx={{ float: "right" }}
                  >
                    {formatUtils.formatMoney(0)}
                  </Typography>
                </td>
              </tr>
            </table>
          </div>
          <div style={{ padding: "16px", textAlign: "center" }}>
            <HomebaseButton disabled>Redeem Rent</HomebaseButton>
            <Typography variant="caption" className="grey-text">
              Next redemption available: February 15
            </Typography>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper className="dash-card">
          <Typography variant="h6" className="semi-bold" sx={{ p: 2 }}>
            Purchase Information
          </Typography>
          <DataDisplay
            data={[
              { name: "Street Address", value: "Value" },
              { name: "City", value: "Value" },
              { name: "State", value: "Value" },
              { name: "Zip", value: "Value" },
              { name: "Shares Owned", value: "Value" },
              { name: "Initial Investment", value: "Value" },
              { name: "Origin", value: "Value" },
            ]}
          />
          <div style={{ padding: "16px" }}>
            <HomebaseButton>Property Page</HomebaseButton>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TransactionSummary />
      </Grid>
    </>
  );
};

interface PropertyProps {
  id: any;
}

const Property = (props: PropertyProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`properties/${props.id}`);
  };

  return (
    <tr className="clickable-row" onClick={handleClick}>
      <td
        className="table-data"
        style={{ width: "100px", paddingLeft: "32px", paddingRight: "16px" }}
      >
        <img
          src="https://cabinet-storage-production.imgix.net/7f2e8378-bd31-53fc-844a-89dd3aaa6d7e.jpg?w=2000&fit=crop&s=d8016c80415aa3d8d1f1760770404699"
          alt="home"
          className="prop-image"
        />
      </td>
      <td className="table-data">
        <Typography variant="body1" className="semi-bold">
          The Cardinal
        </Typography>
        <Typography variant="caption" className="grey-text">
          McAllen, TX
        </Typography>
      </td>
      <td
        className="table-data"
        style={{ textAlign: "end", paddingRight: "32px" }}
      >
        <Typography variant="body1" className="blue-text">
          {formatUtils.formatMoney(300000)}
        </Typography>
        <Typography variant="caption" className="grey-text">
          {formatUtils.formatMoney(10000)} Invested
        </Typography>
      </td>
    </tr>
  );
};
