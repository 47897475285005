import { Box, Button, CircularProgress } from "@mui/material";

interface ButtonProps {
  children: any;
  loading?: any;
  onClick?: any;
  disabled?: boolean;
}

export const HomebaseButton = ({
  children,
  loading,
  onClick,
  disabled,
}: ButtonProps) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Button
        fullWidth
        variant="contained"
        style={
          loading || disabled
            ? { borderRadius: "20px", textTransform: "none" }
            : {
                borderRadius: "20px",
                backgroundColor: "#0f6eff",
                textTransform: "none",
              }
        }
        onClick={onClick}
        disabled={loading || disabled}
      >
        {children}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </Box>
  );
};
