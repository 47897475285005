import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import {
  Box,
  Breakpoint,
  CircularProgress,
  Container,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { Header } from "./common/header/Header";
import { Footer } from "./common/footer/Footer";
import { AuthProvider, ProtectedPage } from "./context/auth";
import { lazy, Suspense } from "react";
import { DashboardPage } from "./dashboard/Dashboard";

const MarketplacePage = lazy(() => import("./marketplace/Marketplace"));
const PropertyPage = lazy(() => import("./property/Property"));
const ConfirmationPage = lazy(() => import("./confirmation/Confirmation"));
const EarlyAccessPage = lazy(() => import("./earlyaccess/EarlyAccess"));

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
});

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <div style={{ position: "relative" }}>
            <Routes>
              <Route
                path="/"
                element={
                  <ProtectedPage>
                    <PageWrap maxWidth="xl" page={<MarketplacePage />} />
                  </ProtectedPage>
                }
              />
              <Route
                path="property"
                element={
                  <ProtectedPage>
                    <PageWrap page={<PropertyPage />} />
                  </ProtectedPage>
                }
              />
              <Route
                path="confirmation"
                element={
                  <ProtectedPage>
                    <PageWrap page={<ConfirmationPage />} />
                  </ProtectedPage>
                }
              />
              <Route
                path="dashboard/*"
                element={
                  <ProtectedPage>
                    <DashboardPage />
                  </ProtectedPage>
                }
              />
              <Route path="login" element={<EarlyAccessPage />} />
            </Routes>
          </div>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  );
}

interface PageProps {
  page: JSX.Element;
  maxWidth?: false | Breakpoint;
}

const PageWrap = (props: PageProps) => {
  const { page, maxWidth } = props;

  const width = maxWidth || "lg";

  return (
    <>
      <Header maxWidth={width} />
      <Container maxWidth={width} className="page-wrap">
        <Suspense
          fallback={
            <Box display="flex" height="600px">
              <CircularProgress sx={{ margin: "auto" }} />
            </Box>
          }
        >
          {page}
        </Suspense>
      </Container>
      <Footer maxWidth={width} />
    </>
  );
};

export default App;
