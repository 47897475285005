import { createContext, ReactNode, useState, useContext } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

interface IAuthContext {
  isAuthed: boolean;
  login: (pwd: string) => void;
}

const TARGET = "hbearlyaccess";
const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const getDefaultValue = () => {
  const code = localStorage.getItem("code");
  if (code && code === TARGET) {
    return true;
  }

  return false;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthed, setIsAuthed] = useState<boolean>(getDefaultValue());
  const navigate = useNavigate();

  const login = (pwd: string) => {
    if (pwd === TARGET) {
      localStorage.setItem("code", pwd);
      setIsAuthed(true);
      navigate("/");
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthed, login }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const ProtectedPage = ({ children }: { children: JSX.Element }) => {
  const { isAuthed } = useAuth();
  const location = useLocation();

  if (!isAuthed) {
    console.log(isAuthed);
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};
