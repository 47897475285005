import { Typography } from "@mui/material";

interface DataDisplayProps {
  data: { name: string; value: string }[];
}

export const DataDisplay = ({ data }: DataDisplayProps) => {
  return (
    <table width="100%">
      {data.map((entry) => (
        <tr>
          <td className="table-data" style={{ padding: "0px 32px 16px 32px" }}>
            <Typography variant="body1" className="semi-bold">
              {entry.name}
            </Typography>
          </td>
          <td className="table-data" style={{ padding: "0px 32px 16px 32px" }}>
            <Typography
              variant="body1"
              className="grey-text"
              sx={{ float: "right" }}
            >
              {entry.value}
            </Typography>
          </td>
        </tr>
      ))}
    </table>
  );
};
