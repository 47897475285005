import {
  Breakpoint,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import logo_stacked from "../../static/logo_stacked.svg";
import housing from "../../static/housing.svg";
import { footerSections } from "./constants";
import "./Footer.css";

interface FooterProps {
  maxWidth: Breakpoint;
}

export const Footer = ({ maxWidth }: FooterProps) => {
  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        width: "100%",
      }}
    >
      <Divider />
      <Container
        maxWidth={maxWidth}
        sx={{ display: { xs: "none", sm: "flex" } }}
      >
        <table width={"100%"} style={{ padding: "20px 0px" }}>
          <tbody>
            <tr>
              <td rowSpan={4} colSpan={2}>
                <img src={logo_stacked} alt="logo" className="footer-logo" />
              </td>
              <td className="footer-text">
                <a
                  href="https://homebasedao.io/"
                  className="footer-link clickable"
                >
                  <Typography variant="body1">Homebase</Typography>
                </a>
              </td>
              <td className="footer-text">
                <a
                  href="https://homebasedao.io/about"
                  className="footer-link clickable"
                >
                  <Typography variant="body1">About Us</Typography>
                </a>
              </td>
            </tr>
            <tr>
              <td className="footer-text">
                <a
                  href="https://homebasedao.io/#how"
                  className="footer-link clickable"
                >
                  <Typography variant="body1">How It Works</Typography>
                </a>
              </td>
              <td className="footer-text">
                <a
                  href="https://homebasedao.io/blog"
                  className="footer-link clickable"
                >
                  <Typography variant="body1">Blog</Typography>
                </a>
              </td>
            </tr>
            <tr>
              <td className="footer-text">
                <a
                  href="https://homebasedao.io/#roadmap"
                  className="footer-link clickable"
                >
                  <Typography variant="body1">Roadmap</Typography>
                </a>
              </td>
              <td className="footer-text">
                <a
                  href="https://homebasedao.io/projects"
                  className="footer-link clickable"
                >
                  <Typography variant="body1">Projects</Typography>
                </a>
              </td>
            </tr>
            <tr>
              <td className="footer-text">
                <a
                  href="https://homebasedao.io/#faq"
                  className="footer-link clickable"
                >
                  <Typography variant="body1">FAQ</Typography>
                </a>
              </td>
              <td className="footer-text">
                <a
                  href="https://www.notion.so/homebasedao/Media-Kit-61cca788fcde47c7a7eab87459bca652"
                  target="_blank"
                  className="footer-link clickable"
                  rel="noreferrer"
                >
                  <Typography variant="body1">Media Kit</Typography>
                </a>
              </td>
            </tr>
            <tr>
              <td style={{ paddingTop: "20px" }}>
                {footerSections.map((section, index) => (
                  <span key={index}>
                    <a href={section.ref} target="_blank" rel="noreferrer">
                      <img
                        src={section.icon}
                        alt="title"
                        className="clickable social-logo"
                      />
                    </a>
                  </span>
                ))}
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <Typography variant="caption">Have any questions?</Typography>
                <br />
                <Typography variant="caption">
                  <a href="mailto:info@homebasedao.io" className="clickable">
                    info@homebasedao.io
                  </a>
                </Typography>
              </td>
              <td className="footer-text">
                <img src={housing} alt="title" />
              </td>
            </tr>
            <tr>
              <td colSpan={4} className="footer-text">
                <Typography variant="caption">
                  2022 Homebase. All rights reserved.
                </Typography>
              </td>
            </tr>
            <tr>
              <td
                colSpan={4}
                className="grey-text"
                style={{ paddingTop: "16px", textAlign: "justify" }}
              >
                <Typography variant="caption">
                  This site is operated by HomebaseDAO, Inc., which is not a
                  registered broker-dealer or investment advisor. HomebaseDAO,
                  Inc. does not give investment advice, endorsement, analysis or
                  recommendations with respect to any securities. Nothing on
                  this website should be construed as an offer to sell,
                  solicitation of an offer to buy or a recommendation for any
                  security by HomebaseDAO, Inc. or any third party. You are
                  solely responsible for determining whether any investment,
                  investment strategy, security, or related transaction is
                  appropriate for you based on your personal investment
                  objectives, financial circumstances and risk tolerance. You
                  should consult with licensed legal professionals and
                  investment advisors for any legal, tax, insurance or
                  investment advice. HomebaseDAO, Inc. does not guarantee any
                  investment performance, outcome or return of capital for any
                  investment opportunity posted on this site.
                </Typography>
                <br />
                <br />
                <Typography variant="caption">
                  All investments involve risk and may result in partial or
                  total loss. By accessing this site, investors understand and
                  acknowledge 1) that investing in real estate, like investing
                  in other fields, is risky and unpredictable; 2) that the real
                  estate industry has its ups and downs; 3) that the real
                  property you invest in might not result in a positive cash
                  flow or perform as you expected; and 4) that the value of any
                  real property you invest in may decline at any time and the
                  future property value is unpredictable. Before making an
                  investment decision, prospective investors are advised to
                  review all available information and consult with their tax
                  and legal advisors. Homebase does not provide investment
                  advice or recommendations regarding any offering posted on
                  this website.
                </Typography>
                <br />
                <br />
                <Typography variant="caption">
                  Any investment-related information contained herein has been
                  secured from sources that Homebase believes to be reliable,
                  but we make no representations or warranties as to the
                  accuracy or completeness of such information and accept no
                  liability therefore. Hyperlinks to third-party sites, or
                  reproduction of third-party articles, do not constitute an
                  approval or endorsement by Homebase of the linked or
                  reproduced content.
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </Container>
      <Container
        maxWidth="lg"
        sx={{ display: { xs: "flex", sm: "none" } }}
        style={{ padding: "8px" }}
      >
        <Grid container alignItems={"center"}>
          <Grid item xs={12} style={{ textAlign: "center", marginTop: "24px" }}>
            <img src={logo_stacked} alt="logo" height={120} />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <div style={{ margin: "24px 0px" }}>
              <div className="footer-mobile">
                <a
                  href="https://homebasedao.io/"
                  className="footer-link clickable"
                >
                  <Typography variant="body2">Homebase</Typography>
                </a>
              </div>
              <div className="footer-mobile">
                <a
                  href="https://homebasedao.io/#how"
                  className="footer-link clickable"
                >
                  <Typography variant="body2">How It Works</Typography>
                </a>
              </div>
              <div className="footer-mobile">
                <a
                  href="https://homebasedao.io/#roadmap"
                  className="footer-link clickable"
                >
                  <Typography variant="body2">Roadmap</Typography>
                </a>
              </div>
              <div className="footer-mobile">
                <a
                  href="https://homebasedao.io/#faq"
                  className="footer-link clickable"
                >
                  <Typography variant="body2">FAQ</Typography>
                </a>
              </div>
              <div className="footer-mobile">
                <a
                  href="https://homebasedao.io/about"
                  className="footer-link clickable"
                >
                  <Typography variant="body2">About Us</Typography>
                </a>
              </div>
              <div className="footer-mobile">
                <a
                  href="https://homebasedao.io/blog"
                  className="footer-link clickable"
                >
                  <Typography variant="body2">Blog</Typography>
                </a>
              </div>
              <div className="footer-mobile">
                <a
                  href="https://homebasedao.io/projects"
                  className="footer-link clickable"
                >
                  <Typography variant="body2">Projects</Typography>
                </a>
              </div>
              <div className="footer-mobile">
                <a
                  href="https://www.notion.so/homebasedao/Media-Kit-61cca788fcde47c7a7eab87459bca652"
                  target="_blank"
                  className="footer-link clickable"
                  rel="noreferrer"
                >
                  <Typography variant="body2">Media Kit</Typography>
                </a>
              </div>
            </div>

            <div>
              {footerSections.map((section, index) => (
                <span key={index}>
                  <a href={section.ref} target="_blank" rel="noreferrer">
                    <img
                      src={section.icon}
                      alt="title"
                      className="clickable social-logo"
                    />
                  </a>
                </span>
              ))}
            </div>
            <div style={{ margin: "24px 0px" }}>
              <img src={housing} alt="title" />
            </div>
            <Typography variant="caption">Have any questions?</Typography>
            <br />
            <Typography variant="caption">
              <a href="mailto:info@homebasedao.io" className="clickable">
                info@homebasedao.io
              </a>
            </Typography>
            <div style={{ width: "100%", margin: "24px 0px" }}>
              <Typography variant="caption">
                2022 Homebase. All rights reserved.
              </Typography>
            </div>
          </Grid>

          <div className="grey-text" style={{ textAlign: "justify" }}>
            <Typography variant="caption">
              This site is operated by HomebaseDAO, Inc., which is not a
              registered broker-dealer or investment advisor. HomebaseDAO, Inc.
              does not give investment advice, endorsement, analysis or
              recommendations with respect to any securities. Nothing on this
              website should be construed as an offer to sell, solicitation of
              an offer to buy or a recommendation for any security by
              HomebaseDAO, Inc. or any third party. You are solely responsible
              for determining whether any investment, investment strategy,
              security, or related transaction is appropriate for you based on
              your personal investment objectives, financial circumstances and
              risk tolerance. You should consult with licensed legal
              professionals and investment advisors for any legal, tax,
              insurance or investment advice. HomebaseDAO, Inc. does not
              guarantee any investment performance, outcome or return of capital
              for any investment opportunity posted on this site.
            </Typography>
            <br />
            <br />
            <Typography variant="caption">
              All investments involve risk and may result in partial or total
              loss. By accessing this site, investors understand and acknowledge
              1) that investing in real estate, like investing in other fields,
              is risky and unpredictable; 2) that the real estate industry has
              its ups and downs; 3) that the real property you invest in might
              not result in a positive cash flow or perform as you expected; and
              4) that the value of any real property you invest in may decline
              at any time and the future property value is unpredictable. Before
              making an investment decision, prospective investors are advised
              to review all available information and consult with their tax and
              legal advisors. Homebase does not provide investment advice or
              recommendations regarding any offering posted on this website.
            </Typography>
            <br />
            <br />
            <Typography variant="caption">
              Any investment-related information contained herein has been
              secured from sources that Homebase believes to be reliable, but we
              make no representations or warranties as to the accuracy or
              completeness of such information and accept no liability
              therefore. Hyperlinks to third-party sites, or reproduction of
              third-party articles, do not constitute an approval or endorsement
              by Homebase of the linked or reproduced content.
            </Typography>
          </div>
        </Grid>
      </Container>
    </div>
  );
};
