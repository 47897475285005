import { Divider, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataDisplay } from "../../common/DataDisplay";
import { formatUtils } from "../../utils/format";

export const Transactions = () => {
  const [transactions, setTransactions] = useState<any[]>([]);

  useEffect(() => {
    // call transactions endpoint
    setTransactions([{}]);
  }, []);

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          minHeight: 200,
          maxHeight: 400,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        className="dash-card"
      >
        <Typography variant="h5" className="semi-bold" sx={{ p: 2 }}>
          Transactions
        </Typography>
        <Divider />
        <div
          style={{
            flexGrow: 1,
            maxHeight: "416px",
            overflow: "auto",
          }}
        >
          <table width="100%">
            {transactions.length ? (
              transactions.map((transaction, i) => <Transaction />)
            ) : (
              <tr style={{ height: "99px" }}>
                <td style={{ padding: "16px", textAlign: "center" }}>
                  <Typography variant="body1">
                    You have no transactions
                  </Typography>
                </td>
              </tr>
            )}
          </table>
        </div>
        <Divider />
        <div style={{ padding: "16px" }}>
          <Typography
            variant="h6"
            className="grey-text semi-bold"
            sx={{ float: "left" }}
          >
            Total Cash Earned
          </Typography>
          <Typography
            variant="h6"
            className="semi-bold blue-text"
            sx={{ float: "right" }}
          >
            {formatUtils.formatMoney(-988.25)}
          </Typography>
        </div>
      </Paper>
    </Grid>
  );
};

export const TransactionSummary = () => {
  const [transactions, setTransactions] = useState<any[]>([]);

  useEffect(() => {
    // call transactions endpoint
    setTransactions([{}]);
  }, []);

  return (
    <Paper
      sx={{
        maxHeight: 400,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      className="dash-card"
    >
      <Typography variant="h5" className="semi-bold" sx={{ p: 2 }}>
        Transactions
      </Typography>
      <Divider />
      <div style={{ flexGrow: 1 }}>
        <table width="100%">
          {transactions.length > 0 ? (
            transactions.map((transaction, i) => <Transaction />)
          ) : (
            <tr style={{ height: "99px" }}>
              <td style={{ textAlign: "center", padding: "16px" }}>
                <Typography variant="body1">
                  You have no transactions
                </Typography>
              </td>
            </tr>
          )}
        </table>
      </div>
      <Divider />
      <div>
        <Typography variant="caption" sx={{ p: 2, float: "right" }}>
          <a href="/dashboard/transactions" className="clickable grey-text">
            View All {">"}
          </a>
        </Typography>
      </div>
    </Paper>
  );
};

export const TransactionDetails = () => {
  return (
    <Grid item xs={12}>
      <Paper
        className="dash-card"
        sx={{ maxWidth: { xs: "100%", sm: "60%", md: "40%" }, margin: "auto" }}
      >
        <Typography variant="h6" className="semi-bold" sx={{ p: 2 }}>
          Transaction #1
        </Typography>
        <DataDisplay
          data={[
            { name: "Date", value: "Value" },
            { name: "Type", value: "Value" },
            { name: "Property", value: "Value" },
            { name: "Status", value: "Value" },
            { name: "Amount", value: "Value" },
            { name: "Origin", value: "Value" },
          ]}
        />
      </Paper>
    </Grid>
  );
};

const Transaction = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`transactions/1`);
  };

  return (
    <tr className="clickable-row" onClick={handleClick}>
      <td className="table-data" style={{ paddingLeft: "32px" }}>
        <Typography variant="body1" className="semi-bold">
          October 2022
        </Typography>
      </td>
      <td
        className="table-data"
        style={{ textAlign: "end", paddingRight: "32px" }}
      >
        <Typography variant="body1" className="blue-text">
          {formatUtils.formatMoney(16000)}
        </Typography>
        <Typography variant="caption" className="grey-text">
          {formatUtils.formatMoney(15000)} invested
        </Typography>
      </td>
    </tr>
  );
};
