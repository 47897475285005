export const formatUtils = {
  formatMoney: (value: number) => {
    if (isNaN(value)) {
      return " ---";
    }

    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  },
  formatNumber: (value: number) => {
    if (isNaN(value)) {
      return " ---";
    }

    return new Intl.NumberFormat("en-US").format(value);
  },
  toTitleCase: (value: string) => {
    return (
      value.charAt(0).toUpperCase() + value.substring(1).toLocaleLowerCase()
    );
  },
};
