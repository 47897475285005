import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import {
  Breadcrumbs,
  Divider,
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { Route, Routes } from "react-router-dom";
import {
  AccountCircle,
  NavigateNext,
  SettingsOutlined,
} from "@mui/icons-material";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import {
  Properties,
  PropertyDetails,
  PropertySummary,
} from "./properties/Properties";
import {
  TransactionDetails,
  Transactions,
  TransactionSummary,
} from "./transactions/Transactions";
import { useEffect } from "react";
import { formatUtils } from "../utils/format";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ShowChartOutlinedIcon from "@mui/icons-material/ShowChartOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import logo from "../static/logo_name.svg";
import "./Dashboard.css";
import { Settings } from "./settings/Settings";

const data = [
  {
    month: "Aug",
    price: 376,
  },
  {
    month: "Sep",
    price: 350,
  },
  {
    month: "Oct",
    price: 250,
  },
  {
    month: "Nov",
    price: 69,
  },
  {
    month: "Dec",
    price: 98,
  },
  {
    month: "Jan",
    price: 314,
  },
];

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: 256,
    width: `calc(100% - ${256}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: 256,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 0,
    }),
  },
}));

export const DashboardPage = () => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const path = window.location.pathname.split("/").filter((x) => x);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: "24px",
            backgroundColor: "white",
            color: "black",
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ textAlign: "center", flexGrow: 1 }}>
            <img src={logo} alt="logo" className="header-logo" />
          </Box>
          <IconButton color="inherit"></IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <Box sx={{ width: 250 }}>
          <List component="nav">
            <ListItemButton href="/dashboard">
              <ListItemIcon>
                <DashboardOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
            <ListItemButton href="/dashboard/properties">
              <ListItemIcon>
                <HomeOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Properties" />
            </ListItemButton>
            <ListItemButton href="/dashboard/transactions">
              <ListItemIcon>
                <ShowChartOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Transactions" />
            </ListItemButton>
            <ListItemButton href="/">
              <ListItemIcon>
                <StorefrontOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Marketplace" />
            </ListItemButton>
            <ListItemButton href="docs">
              <ListItemIcon>
                <MenuBookOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Docs" />
            </ListItemButton>
            <ListItemButton href="/dashboard/settings">
              <ListItemIcon>
                <SettingsOutlined />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItemButton>
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Breadcrumbs separator={<NavigateNext fontSize="small" />}>
                {path.map((value, index) => {
                  const last = index === path.length - 1;
                  const to = `/${path.slice(0, index + 1).join("/")}`;

                  return last ? (
                    <Typography>{formatUtils.toTitleCase(value)}</Typography>
                  ) : (
                    <Link underline="hover" color="inherit" href={to}>
                      {formatUtils.toTitleCase(value)}
                    </Link>
                  );
                })}
              </Breadcrumbs>
            </Grid>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="properties" element={<Properties />} />
              <Route path="properties/:id" element={<PropertyDetails />} />
              <Route path="transactions" element={<Transactions />} />
              <Route path="transactions/:id" element={<TransactionDetails />} />
              <Route path="settings" element={<Settings />} />
            </Routes>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

const Dashboard = () => {
  const [total] = React.useState(10010.42);

  useEffect(() => {
    // make call for history
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Paper
          sx={{
            minHeight: 300,
            display: "flex",
            flexDirection: "column",
          }}
          className="dash-card"
        >
          <div style={{ padding: "16px" }}>
            <Typography variant="h4" className="semi-bold">
              Investing
            </Typography>
            <Typography variant="h4" className="semi-bold">
              {formatUtils.formatMoney(total)}
            </Typography>
          </div>
          <div style={{ height: "200px", flexGrow: 1 }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={data}
                margin={{
                  left: 23,
                  right: 60,
                  top: 16,
                  bottom: 16,
                }}
              >
                <XAxis dataKey="month" />
                <YAxis />
                <Bar
                  dataKey="price"
                  fill="#0f6eff"
                  barSize={10}
                  style={{ borderRadius: 40 }}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <Divider />
          <div style={{ padding: "16px" }}>
            <Typography
              variant="body1"
              className="grey-text"
              sx={{ float: "left" }}
            >
              Total Home Value
            </Typography>
            <Typography
              variant="caption"
              className="grey-text"
              sx={{ float: "right" }}
            >
              {formatUtils.formatMoney(10010.42)}
            </Typography>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <PropertySummary />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TransactionSummary />
      </Grid>
    </>
  );
};
