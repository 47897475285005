import { AccountCircle } from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Box,
  Breakpoint,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import logo from "../../static/logo_name.svg";

interface HeaderProps {
  maxWidth: Breakpoint;
}

export const Header = ({ maxWidth }: HeaderProps) => {
  const isHome = window.location.pathname !== "/";

  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: "white",
        borderWidth: 0,
        borderStyle: "solid",
        borderColor: "rgba(0, 0, 0, 0.12)",
        borderBottomWidth: "thin",
        boxShadow: "none",
      }}
    >
      <Container maxWidth={maxWidth}>
        <Toolbar>
          {isHome && (
            <a
              href="/"
              style={{ position: "absolute", left: 0, textDecoration: "none" }}
            >
              <Typography
                variant="body1"
                className="clickable"
                style={{ color: "#9b9999" }}
              >
                {"< Homes"}
              </Typography>
            </a>
          )}
          <Box sx={{ flexGrow: 1, textAlign: "center" }}>
            <img src={logo} alt="logo" className="header-logo" />
          </Box>
          <IconButton sx={{ position: "absolute", right: 0 }} href="dashboard">
            <Avatar sx={{ width: "32px", height: "32px" }}>A</Avatar>
          </IconButton>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
