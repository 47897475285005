import {
  AccountCircleOutlined,
  BadgeOutlined,
  CreditScoreOutlined,
  EmailOutlined,
  HelpOutline,
  InfoOutlined,
  LockOutlined,
  LogoutOutlined,
  WalletOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { formatUtils } from "../../utils/format";

export const Settings = () => {
  return (
    <Grid
      item
      container
      maxWidth="600px"
      spacing={2}
      sx={{ margin: "auto", p: "0px !important" }}
    >
      <Grid item xs={12} textAlign="center">
        <Avatar sx={{ margin: "auto", height: "60px", width: "60px", mb: 2 }}>
          A
        </Avatar>
        <Typography variant="h6" className="semi-bold">
          Alex
        </Typography>
        <Typography variant="body1" className="grey-text medium">
          Joined May 2022
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Paper className="dash-card" sx={{ p: 1, textAlign: "center" }}>
          <Typography variant="body1" className="semi-bold">
            Properties Owned
          </Typography>
          <Typography variant="body1" className="grey-text">
            12
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper className="dash-card" sx={{ p: 1, textAlign: "center" }}>
          <Typography variant="body1" className="semi-bold">
            Total Invested
          </Typography>
          <Typography variant="body1" className="grey-text">
            {formatUtils.formatMoney(22000)}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <List>
          <ListItemButton divider>
            <ListItemIcon>
              <AccountCircleOutlined />
            </ListItemIcon>
            <ListItemText primary="Name" />
          </ListItemButton>
          <ListItemButton divider>
            <ListItemIcon>
              <EmailOutlined />
            </ListItemIcon>
            <ListItemText primary="Email" />
          </ListItemButton>
          <ListItemButton divider>
            <ListItemIcon>
              <LockOutlined />
            </ListItemIcon>
            <ListItemText primary="Security" />
          </ListItemButton>
          <ListItemButton divider>
            <ListItemIcon>
              <WalletOutlined />
            </ListItemIcon>
            <ListItemText primary="Wallet Address" />
          </ListItemButton>
          <ListItemButton divider>
            <ListItemIcon>
              <BadgeOutlined />
            </ListItemIcon>
            <ListItemText primary="KYC Verification" />
          </ListItemButton>
          <ListItemButton divider>
            <ListItemIcon>
              <CreditScoreOutlined />
            </ListItemIcon>
            <ListItemText primary="Accreditation" />
          </ListItemButton>
          <ListItemButton divider>
            <ListItemIcon>
              <InfoOutlined />
            </ListItemIcon>
            <ListItemText primary="About" />
          </ListItemButton>
          <ListItemButton divider>
            <ListItemIcon>
              <HelpOutline />
            </ListItemIcon>
            <ListItemText primary="Support" />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <LogoutOutlined />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </List>
      </Grid>
    </Grid>
  );
};
